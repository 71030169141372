<template>
  <div>
    <b-container class="base-container pt-2" style="min-height:400px;background-color:#fff">
      <b-row class="text-center d-flex justify-content-center pt-5" v-if="isLoading">
        <b-spinner class="text-danger-light" label="Loading..."></b-spinner>
      </b-row>
      <b-row class="text-center d-flex justify-content-center" v-else>
        <div class="text-center mt-5 mb-5" v-if="!teacher">
          <h2 class="text-primary-dark">{{ $t('Teacher Information') }}</h2>
          <h5 class="text-gray-light">{{ $t('You have not applied for teacher information, if interested, you can apply below.') }}</h5>
          <b-button pill :to="{name: 'applyType', query: { type: 'RTY' }}" class="l-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button>
        </div>
        <div class="text-center mt-5 mb-5" v-else>
          <div v-if="teacher && teacher.status == 1">
            <h2 class="text-primary-dark">{{ $t('Waiting for approve') }}</h2>
            <h5 class="text-gray-light">{{ $t('Waiting for approve from WYA') }}</h5>
            <b-button pill :to="{name: 'teacherRegisterInformation'}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('View detail') }}</b-button>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'TeacherRegister',
  title: ' | Teacher register',
  data () {
    return {
      isLoading: true,
      teacher: null
    }
  },
  computed: {
    teacherId () {
      return this.$store.getters.teacherId
    },
    userProfile () {
      return this.$store.getters.userProfile
    }
  },
  async mounted () {
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    await this.getTeacher()
    // if (this.teacherId) {
    //   this.$router.push({ name: 'teacherProfile' })
    // }
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    getTeacher () {
      this.$store.dispatch('getTeacher', { user: this.userProfile.id }).then(response => {
        if (response.data.results) {
          if (response.data.results.length > 0) {
            this.teacher = response.data.results[0]
            if (this.teacher.status !== 1) {
              this.$router.push({ name: 'teacherProfile' })
            }
          }
        }
        this.isLoading = false
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
